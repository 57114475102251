export const FINANCIALINFO_TITLE = {
  FINANCIALTITLE: 'Financial information',
  FINANCIALTITLENOTE:
    'Please share with us your financial and tax declaration status.',
};
export const BANKINFO_TEXT = {
  BANKINFOTITLE: 'Bank Information',
  BRANCHCODEIDENTIFY: 'How to identify the branch code?',
  BRANCHCODETOOLTIP:
    'Branch code is a 3 digit number. You may refer to the linked page for reference on how to retrieve your branch code based on your bank and account number.',
  BRANCHCODEHYPERLINK: 'http://www.uobgroup.com/ACHcodes',
  UPLOADDOCUMENTLABEL:
    'Please upload document (maximum of 10MB per document) in the following format: JPG, PDF and PNG',
  UPLOADPROOF: 'Proof of bank account details',
};
export const BANKINFO_PLACEHOLDER = {
  BANKSELECT: 'Select a bank',
  BRANCHNAME: 'Branch name',
  BANKACCOUNT: 'Bank account number',
  BRANCHCODE: 'Branch code',
  ANNONG: 'Bank account holder name',
};
export const CREDITREPORT_TEXT = {
  CREDITREPORTTITLE: 'Credit Bureau Reports',
  CBSREPORIMPORTANT: [
    ' The CBS report must be dated within 3 months from the RNF submission date. As the CBS report is time sensitive, please ensure the uploaded CBS report is dated ',
    `within ${process.env.REACT_APP_CBR_VALIDITY} days`,
    ' from the submission of your application.',
  ],
  CBSMLCBREPORIMPORTANT: [
    ' The CBS and MLCB reports must be purchased and submitted ',
    `within ${process.env.REACT_APP_CBR_VALIDITY} days`,
    ' from the submission of this application.',
  ],
  CREDITREPORBUY: 'Buy my credit report',
  UPLOADCREDITBUREAUTITLE: 'Credit Bureau Singapore (CBS) Report',
  UPLOADCREDITBUREAUTOOLTIP:
    'A record of your credit payment history compiled from different credit providers.',
  UPLOADLENDERCREDITBUREUTITLE: 'Moneylenders Credit Bureau (MLCB) Report',
  UPLOADLENDERCREDITBUREUTOOLTIP:
    'A record of your loan payment history compiled from different Licensed Moneylenders.',
  LOANSQUESTION:
    'Do you have any outstanding loans not listed in the CBS report? This includes unsecured loans with bank/non-bank or loan/debt with ex-insurer.',
  CREDITOR: 'Creditor',
  ADDMORE: 'Add more creditor(s)',
  TOTALLOANS: 'Total loan balance',
  UPLOADDOCUMENTLABEL:
    'Please upload the reports in PDF format (maximum 10MB). After uploading each report, please confirm the extracted report date is accurate.',
  VIEWCBSSAMPLEREPORT: 'View CBS sample report',
  BUYCBSREPORT: 'Buy CBS report',
  VIEWMLCBSAMPLEREPORT: 'View MLCB sample report',
  BUYMLCBREPORT: 'Buy MLCB report',
};
export const CREDITREPORT_PLACEHOLDER = {
  CREDITORNAME: 'Creditor name (Bank, Non-bank, ex-insurer)',
  REPAYMENTAMOUNT: 'Repayment amount per month (in SGD)',
  CURRENTLOAN: 'Current loan balance (in SGD)',
  REMAINPAYMENT: 'Remaining payment (in months)',
  LOANTYPE: 'Type of loan',
};
export const TAXRESIDENCE_TEXT = {
  COUNTRYTAX: 'Country of tax residence ',
  CRSINDIVIDUAL: 'CRS Individual Self Certification',
  MOREINFOTEXT: 'For more information',
  MORETININFOTEXT: 'For more information about TIN',
  APPROPRIATEREASON:
    'If a TIN is unavailable please provide the appropriate reason A,B or C',
  ADDMORE: 'Add country of tax residence',
};
export const TAXRESIDENCE_PLACEHOLDER = {
  COUNTRYOFTAX: 'Select country of tax residence',
  TAXIDNUMBER: 'Tax Identification Number (TIN)',
};
export const FATCADECLARATION_TEXT = {
  FATCADECLARATIONTITLE: 'Foreign Account Tax Compliance (FATCA) declaration',
  FATCADECLARATIONQUESTIONLIST: 'Are you affiliated with any of the following?',
  FATCADECLARATIONSELECTOPTIONS: '1. Select the relevant options:',
};
export const FATCADECLARATION_PLACEHOLDER = {
  ATCADECLARATIONDETAIL: 'Please provide more details',
};
export const FATCADECLARATIONAFFILIATE = [
  {
    labelText: '(i) a U.S. Person',
  },
  {
    labelText: '(ii) a U.S. citizen or permanent resident',
  },
  {
    labelText: '(iii) have a U.S. place of birth',
  },
  {
    labelText:
      '(iv) have a current U.S. residence address or U.S. mailing address (including a U.S. post office box)',
  },
  {
    labelText:
      '(v) have a current U.S. telephone number and no non-U.S telephone number',
  },
];
export const FATCAAFFILIATECHECKDATA = [
  {
    id: '',
    value: 'USPerson-01',
    labelText: 'a U.S. Person',
    checked: false,
  },
  {
    id: '',
    value: 'USCitizen-02',
    labelText: 'a U.S. citizen or permanent resident',
    checked: false,
  },
  {
    id: '',
    value: 'USPlace-03',
    labelText: 'have a U.S. place of birth',
    checked: false,
  },
  {
    id: '',
    value: 'USResidence-04',
    labelText:
      'have a current U.S. residence address or U.S. mailing address (including a U.S. post office box)',
    checked: false,
  },
  {
    id: '',
    value: 'USTelephone-05',
    labelText:
      'have a current U.S. telephone number and no non-U.S telephone number',
    checked: false,
  },
];
export const FATCADECLARATIONSOWNLOADDATA = [
  {
    fileName: 'Download W-9 form ',
    url: 'https://www.aia.com.sg/content/dam/sg-wise/en/docs/help-support/forms/W-9-Form.pdf',
  },
  {
    fileName: 'Download W-8BEN form ',
    url: 'https://www.aia.com.sg/content/dam/sg-wise/en/docs/help-support/forms/Form_W_8BEN.pdf',
  },
];
// modal words
export const MOREINFOMODAL_STATICTEXT = {
  TITLE: 'Country of tax residence (CRS Individual Self Certification)',
  COMPLETAINDICATING: 'Please complete the following table indicating',
  COMPLETAINDICATING_1: '(i) where the Account Holder is tax resident; and',
  COMPLETAINDICATING_2:
    '(ii) the Account Holder’s TIN for each Jurisdiction of residence.',
  ACCOUNTHOLDERNOTE:
    'If the Account Holder is not tax resident in any country/jurisdiction (e.g. because it is fiscally transparent), please indicate country of tax residence and TIN.',
  JURISDICTIONSNOTE:
    'Some jurisdictions do not issue a TIN. However, these jurisdictions often utilise some other high integrity number with an equivalent level of identification (a “functional equivalent”). Examples of that type of number include, for individuals, a social security/insurance number, citizen/ personal identification/service code/number, and resident registration number.',
};
export const MOREINFOCONTENTMODAL_TEXT = [
  {
    contentWords:
      'Note:<br> These are selected summaries of defined terms provided to assist you with the completion of this form. Further details can be found within the OECD Common Reporting Standard for Automatic Exchange of Financial Account Information (the CRS”), the associated Commentary to the CRS, and domestic guidance. This can be found at the OECD automatic exchange of information portal. If you have any questions then please contact your tax adviser or domestic tax authority.',
  },
  {
    contentWords:
      '“Account Holder”<br> The term “Account Holder” means the person listed or identified as the holder of a Financial Account. A person, other than a Financial Institution, holding a Financial Account for the bene t of another person as an agent, a custodian, a nominee, a signatory, an investment advisor, an intermediary, or as a legal guardian, is not treated as the Account Holder. In these circumstances, that other person is the Account Holder. For example, in the case of a parent/child relationship where the parent is acting as a legal guardian, the child is regarded as the Account Holder. With respect to a jointly held account, each joint holder is treated as an Account Holder.',
  },
  {
    contentWords:
      '“Entity”<br> The term “Entity” means a legal person or a legal arrangement, such as a corporation, organisation, partnership, trust or foundation.',
  },
  {
    contentWords:
      '“Financial Account”<br> A Financial Account is an account maintained by a Financial Institution and includes: Depository Accounts; Custodial Accounts; Equity and debt interest in certain Investment Entities; Cash Value Insurance Contracts; and Annuity Contracts.',
  },
  {
    contentWords:
      '“Participating Jurisdiction”<br> A Participating Jurisdiction means a jurisdiction with which an agreement is in place pursuant to which it will provide the information required on the automatic exchange of financial account information set out in the Common Reporting Standard and that is identified in the OECD automatic exchange of information portal.',
  },
  {
    contentWords:
      '“Reportable Account”<br> The term “Reportable Account” means an account held by one or more Reportable Persons or by a Passive NFE with one or more Controlling Persons that is a Reportable Person.',
  },
  {
    contentWords:
      '“Reportable Jurisdiction”<br> A Reportable Jurisdiction is a jurisdiction with which an obligation to provide financial account information is in place and that is identified in the OECD automatic exchange of information portal.',
  },
  {
    contentWords:
      '“Reportable Person”<br> A Reportable Person is deemed as an individual who is tax resident in a Reportable Jurisdiction under the tax laws of that jurisdiction. Dual resident individuals may rely on the tiebreaker rules contained in tax conventions (if applicable) to solve cases of double residence for purposes of determining their residence for tax purposes.',
  },
  {
    contentWords:
      '“TIN”<br> (including “functional equivalent”) The term “TIN” means Taxpayer Identification Number or a functional equivalent in the absence of a TIN. A TIN is a unique combination of letters or numbers assigned by a jurisdiction to an individual or an Entity and used to identify the individual or Entity for the purposes of administering the tax laws of such jurisdiction. Further details of acceptable TINs can be found at the OECD automatic exchange of information portal.',
  },
];

export const MandatoryText = 'Mandatory field';
export const DATEOPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const FINANCIALFLIEDNAME = {
  BANKINFO: {
    SELECTBANK: 'selectBank',
    BRANCHNAME: 'branchName',
    BANKACCOUNTNUMBER: 'bankAccountNumber',
    BRANCHCODE: 'branchCode',
    CANDIDATENAME: 'candidateName',
  },
  CBSREPORT: {
    REPORTDATE: 'cbsReportDate',
    MLCBREPORTDATE: 'mlcbReportDate',
    ANSWER: 'answer',
    CREDITORNAME: 'creditorName',
    REPAYMENTAMOUNT: 'repaymentAmount',
    CURRENTLOANBALANCE: 'currentLoanBalance',
    REMAININGPAYMENTTERMS: 'remainingPaymentTerms',
    TYPEOFLOAN: 'typeOfLoan',
  },
  COUNTRYRESIDENCE: {
    COUNTRYTAXRESIDENCE: 'countryTaxResidence',
    TAXIDENTIFICATIONNUMBER: 'taxIdentificationNumber',
    ANSWER: 'answer',
    DETAIL: 'detail',
  },
  FATCADECLARATION: {
    ISAFFILIATEFOLLOW: 'isAffiliatedFollow',
    DETAIL: 'detail',
  },
};
// add err and errMsg
export const BANKINFOERROR = {
  selectBank: false,
  branchName: false,
  bankAccountNumber: false,
  branchCode: false,
  candidateName: false,
  init: false,
};
export const BANKINFOERRMSG = {
  selectBank: '',
  branchName: '',
  bankAccountNumber: '',
  branchCode: '',
  candidateName: '',
};

export const CBSREPORTERROR = {
  creditor: [],
  init: false,
};
export const CBSREPORTERRMSG = {
  creditor: [],
};

export const TAXRESIDENCEERROR = {
  taxResidence: [],
  init: false,
};

export const TAXRESIDENCEERRMSG = {
  taxResidence: [],
};

export const FATCAERROR = {
  detail: false,
  init: false,
};
export const FATCAEERRMSG = {
  detail: '',
};
// upload file questionname question
export const UPLOADFILEQUESTION = {
  BANKINFO: 'financialBankInformation',
  CBSREPORT: 'financialCreditBureauSingaporeReport',
  MLCBREPORT: 'financialCreditBureauMoneyLendersReport',
  FATCA: 'FATCA',
};
export const UPLOADPART = {
  BANKINFO: 'bankInfoResult',
  CBSREPORT: 'creditBureauReportResult',
  FATCA: 'fatcaDeclarationResult',
};
// Reason radio group label
export const TAX_REASON_LABEL = {
  EXPLAINREASON: 'Explain why you are unable to obtain a TIN',
  VALIDITEWORDS: '0 / 500',
  REASONATEXT:
    'The country/jurisdiction where the Account Holder is a resident does not issue TINs to its residents.',
  REASONBTEXT:
    'The Account Holder is unable to obtain a TIN or equivalent number.',
  REASONCTEXT: 'No TIN is required.',
  REASONCOTHERTEXT:
    '(Note. Only select this reason if the domestic law of the relevant jurisdiction does not require the collection of the TIN issued by such jurisdiction)',
};
