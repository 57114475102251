import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { setUnauthorized } from '@redux/app/actions';
import { getStudentConversionStatusRequest } from '@redux/studentConversion/actions';
import { makeSelectToken, makeSelectUserData } from '@redux/app/selectors';
import { BASE_API } from './constants';
import {
  downloadFileUrl,
  getAllComments,
  getCommentsList,
  saveHomeScreenApplicationStatusUrl,
  uploadFileUrl,
} from './apis';
import notif from '@utils/notifications';
import { isArray, cloneDeep } from 'lodash';

const request = (
  url,
  method = 'get',
  params = null,
  token = null,
  customConfig = null
) => {
  let config = {
    method,
    baseURL: BASE_API,
    url,
  };
  if (customConfig !== null) {
    config = Object.assign(config, customConfig);
  }
  if (token !== null) {
    config = Object.assign(config, {
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    });
  }
  if (params !== null) {
    config =
      method === 'get'
        ? Object.assign(config, { params })
        : Object.assign(config, { data: params });
  }
  axios.interceptors.response.use(
    (response) => response,
    async (error) => Promise.reject(error)
  );
  return axios(config);
};

export const parseUrl = (url, params) => {
  let urlString = cloneDeep(url.url);
  if (urlString.match(':prospectId')) {
    if (url === saveHomeScreenApplicationStatusUrl) {
      urlString = urlString.replace(':prospectId', params.userId);
    } else {
      urlString = urlString.replace(':prospectId', params.prospectId);
    }
  }
  if (urlString.match(':recruitmentProcessId')) {
    if (url === saveHomeScreenApplicationStatusUrl) {
      urlString = urlString.replace(
        ':recruitmentProcessId',
        params.recruitmentProcess.id
      );
    } else {
      urlString = urlString.replace(
        ':recruitmentProcessId',
        params.recruitmentProcessId
      );
    }
  }
  if (urlString.match(':creatorId')) {
    urlString = urlString.replace(':creatorId', params.creatorId);
  }
  if (urlString.match(':countryTaxResidenceId')) {
    urlString = urlString.replace(
      ':countryTaxResidenceId',
      params.countryTaxResidenceId
    );
  }
  if (urlString.match(':id')) {
    urlString = urlString.replace(':id', params.id);
  }
  if (urlString.match(':personalInfoEducationBackgroundItemId')) {
    urlString = urlString.replace(
      ':personalInfoEducationBackgroundItemId',
      params.personalInfoEducationBackgroundItem?.id
    );
  }
  if (urlString.match(':personalInfoFamilyInfoItemId')) {
    urlString = urlString.replace(
      ':personalInfoFamilyInfoItemId',
      params.personalInfoFamilyInfoItem?.id
    );
  }
  if (urlString.match(':personalInfoEmploymentHistoryItemId')) {
    urlString = urlString.replace(
      ':personalInfoEmploymentHistoryItemId',
      params.personalInfoEmploymentHistoryItem?.id
    );
  }
  if (urlString.match(':fileId')) {
    urlString = urlString.replace(':fileId', params.fileId);
  }
  return urlString;
};

export const tssBasicRequest = (
  url,
  method = 'get',
  params = null,
  token = null,
  customConfig = null
) => {
  const requestUrl = url.url ? parseUrl(url, params) : url;
  const requestMethod = url.method ?? method;
  const requestParams =
    requestMethod === 'get' //|| requestMethod === 'delete'
      ? {}
      : params;
  const config = { timeout: 10000, ...customConfig };
  return request(requestUrl, requestMethod, requestParams, token, config)
    .then((res) => {
      // comment is empty means was deleted, need to filter
      if (
        (url === getCommentsList || url === getAllComments) &&
        res?.data?.success?.status
      ) {
        if (url === getCommentsList) {
          requestParams?.fids?.forEach((fid) => {
            if (isArray(res.data.data.commentsList[fid])) {
              res.data.data.commentsList[fid] = res.data.data.commentsList[
                fid
              ]?.filter((item) => item.comment);
            }
          });
        } else if (url === getAllComments && isArray(res.data.data.comments)) {
          res.data.data.comments = res.data.data.comments?.filter(
            (item) => item.comment
          );
        }
      }
      return res;
    })
    .catch((error) => {
      const errMsg = `Network error: ${error?.message}`;
      if (error?.config?.url === uploadFileUrl.url) {
        notif('error', 'Unable to process attached file');
      } else if (error?.message !== 'canceled') {
        notif('error', errMsg);
      }

      return {
        data: {
          success: {
            status: false,
            message: errMsg,
          },
        },
      };
    });
};

export function* callRequestWithToken({ method, url, params, config = {} }) {
  try {
    let token = yield select(makeSelectToken());

    const customConfig = {
      ...config,
      headers: { ...config.headers },
    };
    const res = yield call(
      tssBasicRequest,
      url,
      method,
      params,
      token,
      customConfig
    );
    return res;
  } catch (error) {
    return {
      data: {
        success: {
          status: false,
          message: `Request catch error: ${error?.message}`,
        },
      },
    };
  }
}

export function requestDownloadFile({ fileId, userId, token, config }) {
  return tssBasicRequest(
    downloadFileUrl,
    'post',
    {
      userId: userId,
      id: fileId,
    },
    token,
    {
      ...config,
      responseType: 'blob',
    }
  );
}

export function caaDownloadFile({ token, config }) {
  return tssBasicRequest(
    '/candidate/career-aptitude-assessment/report',
    'get',
    null,
    token,
    {
      ...config,
      responseType: 'blob',
    }
  );
}

export const studentConversionDownloadFile = ({ fileId, token, config }) => {
  return tssBasicRequest(
    `/student-conversion/file/${fileId}`,
    'get',
    null,
    token,
    {
      ...config,
      responseType: 'blob',
    }
  );
};

export function* requestDownloadFileWithToken({ fileId, userId }) {
  try {
    let token = yield select(makeSelectToken());

    const res = yield call(
      tssBasicRequest,
      downloadFileUrl,
      'post',
      { userId: userId, id: fileId },
      token,
      { responseType: 'blob' }
    );
    return res;
  } catch (error) {
    return {
      data: {
        success: {
          status: false,
          message: `Request catch error: ${error?.message}`,
        },
      },
    };
  }
}

export function requestUploadFile({ params, token }) {
  return tssBasicRequest(uploadFileUrl, 'post', params, token, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
export function* requestUploadFileWithToken(params) {
  try {
    let token = yield select(makeSelectToken());

    const res = yield call(
      tssBasicRequest,
      uploadFileUrl,
      'post',
      params,
      token,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return res;
  } catch (error) {
    return {
      data: {
        success: {
          status: false,
          message: `Request catch error: ${error?.message}`,
        },
      },
    };
  }
}

export function sendEmail({ params, token }) {
  return tssBasicRequest(
    {
      url: `/recruitment-process/${params.recruitmentProcessId}/sendEmail/${params.emailType}`,
      method: 'put',
    },
    'put',
    params,
    token
  );
}

export function* unauthorizedHandler(response) {
  try {
    if (response && response.status === 401) {
      yield put(setUnauthorized());
    }
  } catch (e) {
    console.error(e);
  }
}

export const handleLogout = async (accessToken) => {
  return axios.post(`${BASE_API}/auth/okta-external/logout`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export function* updateStudentConversionState(response) {
  try {
    if ((response && response.status === 200) || response.status === 204) {
      let userData = yield select(makeSelectUserData());
      const epsApplicationId = userData.get('epsApplicationId');
      if (epsApplicationId) {
        yield put(getStudentConversionStatusRequest(epsApplicationId));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export default request;
