import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  proceedBasicInfoError,
  proceedBasicInfoSuccess,
  setBasicInfoData,
  getMyInfoProfileError,
  setIsInitCompleted,
  setMyInfoProfileData,
  setBlackStatus,
  setAgeStatus,
} from './action';
import {
  GET_BASICINFO_REQUEST,
  SAVE_BASICINFO_REQUEST,
  GET_MY_INFO_PROFILE_REQUEST,
  GET_DATA_LIST_REQUEST,
} from './actionTypes';
import {
  convertSingPassData,
  getBasicInfoUrl,
  getFileListUrl,
  queryDataListNricUrl,
  saveBasicInfoUrl,
} from '@utils/apis';
import { makeSelectRecruitmentProcessIds } from '@redux/fscApplication/eobCommonInfo/selectors';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import { makeSelectIsInitCompleted } from './selectors';
import { PAGE_NAME } from '@utils/constants';
import { PERSONAL_INFO_DOCUMENTS } from '@pages/fscApplication/PersonalInformation/business/constants';
import { formatBytes } from '@utils/helper';
import { setBasicFileData } from '../common/action';
import moment from 'moment';

export function* getBasicInfoAction({ data, callback }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };

    const res = yield callRequestWithToken({
      method: 'get',
      url: getBasicInfoUrl,
      params,
    });

    if (res.data.success.status) {
      if (res.data?.data?.personalInfoBasic?.contactNumber) {
        callback &&
          callback({
            companyType: 'Blacklist',
            candidateNric: res.data.data.personalInfoBasic.idNumber,
            candidateName: res.data.data.personalInfoBasic.fullName,
          });
      }

      yield put(setBasicInfoData(res.data?.data?.personalInfoBasic ?? {}));

      if (res.data?.data?.personalInfoBasic?.birthday) {
        const age =
          moment().diff(res.data.data.personalInfoBasic.birthday, 'years') ?? 0;
        const isAgeInvalid = age < 21;
        yield put(setAgeStatus(isAgeInvalid));
      }
      const isInitCompleted = yield select(makeSelectIsInitCompleted());
      if (!isInitCompleted) {
        yield put(setIsInitCompleted(true));
      }
      yield put(proceedBasicInfoSuccess());
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedBasicInfoError(err.response?.data?.success?.message));
  }
}

export function* saveBasicInfoAction({
  data,
  fieldName,
  setIsLoading,
  callback,
}) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );

    let params = {};
    if (
      fieldName === 'selectIdType' ||
      (fieldName === 'isSingPass' && data.isSingPass) ||
      fieldName === 'contactNumber'
    ) {
      params = {
        userId: recruitmentProcessIds.get('prospectId'),
        recruitmentProcessId: recruitmentProcessIds.get('id'),
        personalInfoBasic: data,
      };
    } else {
      params = {
        userId: recruitmentProcessIds.get('prospectId'),
        recruitmentProcessId: recruitmentProcessIds.get('id'),
        personalInfoBasic: {
          id: data.id,
          [fieldName]: data[fieldName],
        },
      };
    }

    if (setIsLoading) {
      if (fieldName === 'selectCitizenship')
        setIsLoading({ isImmediate: true, isLoading: true });
      else setIsLoading(true);
    }

    if (
      (fieldName === 'birthday' && data.birthday) ||
      (fieldName === 'isSingPass' && data.isSingPass)
    ) {
      const age = moment().diff(data.birthday, 'years') ?? 0;
      const isAgeInvalid = age < 21;
      yield put(setAgeStatus(isAgeInvalid));
      callback && callback(isAgeInvalid);
    }
    const res = yield callRequestWithToken({
      method: 'post',
      url: saveBasicInfoUrl,
      params,
    });

    if (res.data.success.status) {
      if (!data.id || data.id !== res.data?.data?.id) {
        data.id = res.data?.data?.id;
      }
      yield put(setBasicInfoData(data));

      if (fieldName === 'isSingPass') {
        const fileParams = {
          recruitmentProcessId: recruitmentProcessIds.get('id'),
          pageName: PAGE_NAME.PERSONAL_INFORMATION.pageKey,
          question: PERSONAL_INFO_DOCUMENTS.BASIC_INFO_SECTION.MY_INFO,
        };
        const response = yield callRequestWithToken({
          method: 'post',
          url: getFileListUrl,
          params: fileParams,
        });
        if (response.data.success.status) {
          const fileList = response.data.data.fileList;
          fileList.forEach((documentInfo) => {
            documentInfo.name = documentInfo.fileName;
            documentInfo.size = formatBytes(documentInfo.fileSize * 1);
          });
          yield put(setBasicFileData(fileList));
          setIsLoading && setIsLoading(false);
        } else {
          yield put(proceedBasicInfoError(response?.data?.success?.message));
          setIsLoading && setIsLoading(false);
        }
      } else {
        setIsLoading && setIsLoading(false);
      }
    } else {
      yield put(proceedBasicInfoError(res?.data?.success?.message));
      setIsLoading && setIsLoading(false);
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedBasicInfoError(err.response?.data?.success?.message));
    setIsLoading && setIsLoading(false);
  }
}
export function* getMyInfoProfileAction() {
  try {
    const user = yield callRequestWithToken({
      method: 'get',
      url: '/candidate',
      params: null,
    });

    if (user.data.success.status) {
      // TODO -- convert data format for Race and Nationality
      const myInfo = user.data?.data?.myInfo;
      const res = yield callRequestWithToken({
        method: 'put',
        url: convertSingPassData,
        params: {
          raceCode: myInfo.Race.code,
          nationalityCode: myInfo.Nationality.code,
        },
      });
      if (res.data.success.status) {
        myInfo.Race.convertedName = res.data?.data?.race;
        myInfo.Nationality.convertedName = res.data?.data?.nationality;
        yield put(setMyInfoProfileData(myInfo));
      } else {
        yield put(setMyInfoProfileData({}));
      }
    } else {
      yield put(setMyInfoProfileData({}));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(getMyInfoProfileError(err.response?.data?.success?.message));
  }
}

export function* queryDataListNricAction({ data, callback }) {
  try {
    const res = yield callRequestWithToken({
      method: 'post',
      url: queryDataListNricUrl,
      params: data,
    });
    yield put(setBlackStatus(res.data.data.result.length > 0));
    callback && callback(res.data.data.result.length > 0);
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedBasicInfoError(err.response?.data?.success?.message));
  }
}
export default function* rootSaga() {
  yield takeLatest(GET_BASICINFO_REQUEST, getBasicInfoAction);
  yield takeEvery(SAVE_BASICINFO_REQUEST, saveBasicInfoAction);
  yield takeLatest(GET_MY_INFO_PROFILE_REQUEST, getMyInfoProfileAction);
  yield takeLatest(GET_DATA_LIST_REQUEST, queryDataListNricAction);
}
