import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_HOME_SCREEN_APPLICATION_STATUS,
  GET_STEP_BAR_REQUEST,
  SAVE_HOME_SCREEN_APPLICATION_STATUS,
  SAVE_STEP_BAR_REQUEST,
} from './actionTypes';
import {
  proceedEobCommonInfoSuccess,
  proceedEobCommonInfoError,
  setStepStatus,
  setHomeScreenApplicationStatus,
  proceedStepBarSuccess,
  proceedStepBarError,
  getStepBarRequest,
} from './actions';
import { callRequestWithToken, unauthorizedHandler } from '@utils/request';
import {
  getHomeScreenApplicationStatusUrl,
  getStepBarUrl,
  saveHomeScreenApplicationStatusUrl,
  saveStepBarUrl,
} from '@utils/apis';
import {
  makeSelectHomeScreenApplicationStatus,
  makeSelectRecruitmentProcessIds,
} from './selectors';
import { setUserData } from '@redux/app/actions';

export function* getStepBarAction({ data }) {
  try {
    const params = {
      userId: data.prospectId,
      recruitmentProcessId: data.id,
    };
    const response = yield callRequestWithToken({
      method: 'get',
      url: getStepBarUrl,
      params,
    });
    if (response.data?.success?.status) {
      yield put(setStepStatus(response.data?.data?.stepBar ?? {}));
      yield put(proceedStepBarSuccess('Successfully get stepBar data'));
    } else {
      yield put(proceedStepBarError('Failed get stepBar data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedStepBarError(err.response?.data?.success?.message));
  }
}

export function* saveStepBarAction({ data }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      stepBar: data,
    };
    const getStepBarParams = {
      id: recruitmentProcessIds.get('id'),
    };
    const response = yield callRequestWithToken({
      method: 'post',
      url: saveStepBarUrl,
      params,
    });
    if (response.data?.success?.status) {
      yield put(proceedStepBarSuccess('Successfully save stepBar data'));
      yield put(getStepBarRequest(getStepBarParams));
    } else {
      yield put(proceedStepBarError('Failed save stepBar data'));
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedStepBarError(err.response?.data?.success?.message));
  }
}

export function* getHomeScreenApplicationStatusAction({ data }) {
  try {
    const params = {
      prospectId: data,
    };
    if (!params.prospectId) {
      const user = yield callRequestWithToken({
        method: 'get',
        url: '/candidate',
        params: null,
      });
      if (user?.data?.success?.status) {
        yield put(setUserData(user.data.data));
        params.prospectId = user?.data?.data?.id;
      } else {
        yield put(setUserData({}));
        yield put(proceedEobCommonInfoError('Failed get candidate data'));
        return;
      }
    }
    const response = yield callRequestWithToken({
      method: 'post',
      url: getHomeScreenApplicationStatusUrl,
      params,
    });
    if (response.data?.success?.status) {
      yield put(
        setHomeScreenApplicationStatus(
          response.data?.data?.homeScreenApplicationStatus
            ?.recruitmentProcess ?? {}
        )
      );
      yield put(
        proceedEobCommonInfoSuccess(
          'Successfully get home screen application status data'
        )
      );
    } else {
      yield put(
        proceedEobCommonInfoError(
          'Failed get home screen application status data'
        )
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEobCommonInfoError(err.response?.data?.success?.message));
  }
}

export function* saveHomeScreenApplicationStatusAction({ data }) {
  try {
    const recruitmentProcessIds = yield select(
      makeSelectRecruitmentProcessIds()
    );
    const params = {
      userId: recruitmentProcessIds.get('prospectId'),
      recruitmentProcess: data,
    };
    const response = yield callRequestWithToken({
      method: 'post',
      url: saveHomeScreenApplicationStatusUrl,
      params,
    });
    if (response.data?.success?.status) {
      const homeScreenApplicationStatus = yield select(
        makeSelectHomeScreenApplicationStatus()
      );
      const homeScreenApplicationStatusData =
        homeScreenApplicationStatus.toJS();
      yield put(
        setHomeScreenApplicationStatus({
          ...homeScreenApplicationStatusData,
          ...data,
        })
      );
      yield put(
        proceedEobCommonInfoSuccess(
          'Successfully save home screen application status data'
        )
      );
    } else {
      yield put(
        proceedEobCommonInfoError(
          'Failed save home screen application status data'
        )
      );
    }
  } catch (err) {
    const { response } = err;
    yield call(unauthorizedHandler, response);
    yield put(proceedEobCommonInfoError(err.response?.data?.success?.message));
  }
}

export default function* rootSaga() {
  yield takeLatest(GET_STEP_BAR_REQUEST, getStepBarAction);
  yield takeLatest(SAVE_STEP_BAR_REQUEST, saveStepBarAction);
  yield takeLatest(
    GET_HOME_SCREEN_APPLICATION_STATUS,
    getHomeScreenApplicationStatusAction
  );
  yield takeLatest(
    SAVE_HOME_SCREEN_APPLICATION_STATUS,
    saveHomeScreenApplicationStatusAction
  );
}
